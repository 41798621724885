import type { SearchResponse } from "src/api/SearchResponse";
import type { StaticSchedulesPassengerDetails } from "../PassengerDetailsProvider";
import type { SupportedLanguageCode } from "./language";
import { tripHashFromSearchResponse } from "./location/createTripHashForCard";
import type { HotelProviderKind } from "./types/accommodationProviders";
import { urlConfig } from "./urlConfig";
import type { TemplatedTicketUrl } from "./adapters/ticketInfo";

export function getPath(
  originCanonical: string | undefined,
  destinationCanonical: string | undefined,
  languageCode: SupportedLanguageCode
) {
  const languageCodeInPath = getLanguageForUrl(languageCode);

  const parts = [];
  if (languageCodeInPath) {
    parts.push(languageCodeInPath);
  }
  parts.push("map");
  if (originCanonical) {
    parts.push(encodeURIComponent(originCanonical));
    // You can only have a destinationCanonical if you have an originCanonical.
    if (destinationCanonical) {
      parts.push(encodeURIComponent(destinationCanonical));
    }
  }

  return `/${parts.join("/")}`;
}

function getLanguageForUrl(languageCode: SupportedLanguageCode) {
  return languageCode === "en" ? undefined : languageCode;
}

export function getQueryParamValue(
  queryParam: string,
  locationSearch: string | undefined
): string | undefined {
  if (!locationSearch) {
    return undefined;
  }

  const match = locationSearch.match(new RegExp(`${queryParam}=([^&]+)`));
  return match ? match[1] : undefined;
}

export function getTicketsUrlFromTemplate(
  template: TemplatedTicketUrl,
  exitPoint: string,
  departureDate?: string, // YYYY-MM-DD,
  returnDate?: string, // YYYY-MM-DD
  passengerDetails?: StaticSchedulesPassengerDetails
): string {
  // API 1.5 gives us specific templated parameters. Here, we fill in ones
  // that we have values for.
  function substituteParam(url: string, param: string) {
    switch (param) {
      case "oDateTime":
        return replaceParam(url, param, departureDate);
      case "iDateTime":
        return replaceParam(url, param, returnDate);
      case "checkoutExitLabel":
        return replaceParam(url, param, exitPoint);
      case "adults":
        return replaceParam(
          url,
          param,
          passengerDetails ? passengerDetails.adults : ""
        );
      case "seniors":
        return replaceParam(
          url,
          param,
          passengerDetails ? passengerDetails.seniors : ""
        );
      case "youths":
        return replaceParam(
          url,
          param,
          passengerDetails ? passengerDetails.youths : ""
        );
      case "ages":
        return replaceParam(
          url,
          param,
          passengerDetails ? passengerDetails.ages : ""
        );
      default:
        return replaceParam(url, param, "");
    }
  }

  return template.templatedParameters.reduce(
    (urlWithSubstitutes: string, param) => {
      return substituteParam(urlWithSubstitutes, param);
    },
    template.url
  );
}

function replaceParam(url: string, param: string, value?: string) {
  return url.replace(`{${param}}`, value ? encodeURIComponent(value) : "");
}

type QueryParams = { [name: string]: string | number | boolean | undefined };

export function buildUrl(
  base: string,
  queryParams: QueryParams,
  options?: { useApi16: boolean }
) {
  // Remove useIsApi16 and update ApiConfigProvider when api 1.5 has been fully upgraded.
  let urlBase = base;
  if (options?.useApi16) urlBase = base.replace("1.5/json", "1.6");

  const allParams = Object.keys(queryParams);
  const paramsWithValues = allParams.filter((name) => {
    return queryParams[name] != null;
  });
  const paramsForUrl = paramsWithValues
    .map((name) => {
      const encodedParamValue = encodeURIComponent(`${queryParams[name]}`);
      return `${name}=${encodedParamValue}`;
    })
    .join("&");

  // Ensure no slash before query params for consistency with apps, if they're
  // the same we get to re-use the edge cache between products.
  const baseNoEndSlash =
    urlBase[urlBase.length - 1] === "/"
      ? urlBase.slice(0, urlBase.length - 1)
      : urlBase;

  return `${baseNoEndSlash}?${paramsForUrl}`;
}

export type FormattedRoomDetails = {
  Adults: number;
  Children: number;
  ChildAges: (number | null)[];
};

export const getHotelRedirectDeeplinkUrl = (
  languageCode: SupportedLanguageCode,
  exitPoint: string,
  requestId?: string,
  location?: string,
  checkInDate?: string, // YYYY-MM-DDT00:00:00.0000000
  checkOutDate?: string, // YYYY-MM-DDT00:00:00.0000000
  provider?: HotelProviderKind,
  roomDetails?: FormattedRoomDetails[]
) => {
  return buildUrl(`${urlConfig.exitHost}/redirects/hotels`, {
    label: exitPoint,
    requestId,
    location,
    language: languageCode,
    checkin: checkInDate,
    checkout: checkOutDate,
    provider: provider,
    hotelRooms: JSON.stringify(roomDetails),
  });
};

export function getCarHireDeeplinkRedirectUrl(
  requestId: string | undefined, // requestId will be undefined if developing locally w/o a UID cookie
  exitPoint: string,
  locationName: string,
  lat?: number,
  lng?: number
) {
  return buildUrl(`${urlConfig.exitHost}/redirects/cars`, {
    requestId,
    label: exitPoint,
    location: locationName,
    locationLat: lat,
    locationLng: lng,
  });
}

export function getHomepageUrl(languageCode: SupportedLanguageCode) {
  const languageCodeInPath = getLanguageForUrl(languageCode);
  const languagePath = languageCodeInPath ? `/${languageCodeInPath}` : "";
  return `${urlConfig.homepageHost}${languagePath}`;
}

export function getTicketsHomepageUrl(language: SupportedLanguageCode) {
  const languageForUrl = getLanguageForUrl(language);
  const languagePath = languageForUrl ? `/${languageForUrl}` : "";
  return `${urlConfig.homepageHost}${languagePath}/tickets`;
}

export function getHash(
  hash: string,
  tripPlannerPrefix: boolean = false,
  searchResponse?: SearchResponse
) {
  if (hash) {
    const isTransportDeeplink = hash.startsWith("#r");
    const isTripsTransportDeeplink = isTransportDeeplink && tripPlannerPrefix;

    // Hotels deeplinks will end in /h and include the route/segment index prior.
    // We don't want to override these.
    const isHotelsDeeplink = hash.endsWith("/h") && isTransportDeeplink;

    if (isTripsTransportDeeplink && searchResponse && !isHotelsDeeplink) {
      const searchPrefix = tripHashFromSearchResponse(searchResponse);
      const tripsHash = hash.replace("#", searchPrefix + "/");
      return tripsHash;
    }

    return hash;
  }
  return tripPlannerPrefix ? "#trips" : "";
}
