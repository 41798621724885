import { type ForwardedRef, forwardRef, useRef } from "react";
import styled from "styled-components";
import { useFocusState } from "../../../FocusContext";
import type { AutocompletePlace } from "../../../api/AutocompleteResponse";
import { AutocompleteDropdown } from "../../../components/AutocompleteDropdown/AutocompleteDropdown";
import { BlurAwayListener } from "../../../components/BlurAwayListener/BlurAwayListener";
import type { MergeProps } from "../../../utils/MergeProps";
import { useForkRef } from "../../../utils/useForkRef";
import { type SearchInputProps, SearchInput } from "../SearchInput/SearchInput";

type Props = MergeProps<
  SearchInputProps,
  {
    results: AutocompletePlace[];
    onAutocompleteSelect: (place?: AutocompletePlace) => void;
    className?: string;
    reset: () => void;
  }
>;

function SearchAutocompleteInner(
  props: Props,
  ref: ForwardedRef<HTMLInputElement>
) {
  const hasAutocompleteResults = props.results && props.results.length > 0;
  const { onFocusChanged } = useFocusState();

  const focusRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useForkRef(ref, focusRef);

  return (
    <SearchAutocompleteWrapper
      onBlurAway={() => {
        // When focus leaves this input we update the focus state to indicate
        // that this input isn't focused anymore.
        onFocusChanged({ id: "none", index: 0 });
        props.reset();
      }}
    >
      <StyledSearchInput
        kind={props.kind}
        onChange={props.onChange}
        value={props.value}
        hint={props.hint}
        ref={searchInputRef}
        onKeyDown={props.onKeyDown}
        className={props.className}
        onFocus={(event) => {
          props.onFocus?.(event);
          onFocusChanged({ id: props.kind, index: 0 });
          if (focusRef.current) {
            const textLength = focusRef.current.value.length;
            focusRef.current.setSelectionRange(0, textLength);
          }
        }}
      />
      {hasAutocompleteResults && (
        <StyledAutocompleteDropdown
          results={props.results}
          onSelectPlace={props.onAutocompleteSelect}
        />
      )}
    </SearchAutocompleteWrapper>
  );
}

export const SearchAutocomplete = forwardRef(SearchAutocompleteInner);

const SearchAutocompleteWrapper = styled(BlurAwayListener)`
  position: relative;

  &:focus {
    outline: none;
  }
`;

const StyledSearchInput = styled(SearchInput)`
  z-index: 41;
`;

const StyledAutocompleteDropdown = styled(AutocompleteDropdown)`
  width: 100%;
  /* We want this element underneath the search input (above) and the menu dropdowns which have a value of 50. */
  z-index: 40;
  /* The dropdown component uses filter to render a box shadow so that it shadow around a caret element.
  Using filter on an element creates a stacking context and moves it to the top, causing the shadow to
  overlay the search input above (see https://drafts.fxtf.org/filter-effects/#FilterProperty). Since we're
  not using a caret here, use box-shadow instead so we get the correct shadow depth underneath the input. */
  filter: none;
  box-shadow: 0px 14px 50px rgba(0, 0, 0, 0.12);
  background: ${(props) => props.theme.searchBar.autocompleteList.background};
  color: ${(props) => props.theme.searchBar.autocompleteList.text};
`;
