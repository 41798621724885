import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useContext,
} from "react";
import type { GeocodedPlace } from "src/PrefetchData";
import type { useGetTripRoutes } from "src/utils/hooks/useGetTripRoutes";
import type {
  Action,
  HoveredRecommendation,
  TripPlannerDetails,
} from "../TripPlannerProvider";
import type { ApiState } from "../util/api";
import type { useInteractionMade } from "./useInteractionMade";
import type { useTripPlanningState } from "./useTripPlanningState";
import type { useTripDestination } from "./useTripDestination";

export type TripPlannerContextType = {
  tripRoutes: ReturnType<typeof useGetTripRoutes>;
  tripPlannerDetails: TripPlannerDetails;
  dispatch: Dispatch<Action>;
  hoveredPlaceIndex: number | undefined;
  setHoveredPlaceIndex: (index: number | undefined) => void;
  tripPlanningState: ReturnType<typeof useTripPlanningState>;
  apiState: ApiState;
  loadingIndex?: number[];
  reorderingPlaces: GeocodedPlace[];
  setReorderingPlaces: Dispatch<SetStateAction<GeocodedPlace[]>>;
  tripInteraction: ReturnType<typeof useInteractionMade>;
  hoveredRecommendation: HoveredRecommendation;
  setHoveredRecommendation: Dispatch<SetStateAction<HoveredRecommendation>>;
  tripDestination: ReturnType<typeof useTripDestination>;
};

export const TripPlannerContext = createContext<TripPlannerContextType | null>(
  null
);

export function useTripPlannerContext() {
  const context = useContext(TripPlannerContext);
  if (!context) {
    throw new Error(
      `useTripPlannerContext must be used within TripPlannerContext`
    );
  }
  return context;
}
