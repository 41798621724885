import styled from "styled-components";
import { spacing, color } from "src/theme";
import { DisplayAd } from "../DisplayAd/DisplayAd";

type MobileHeaderAdProps = {
  showAd: boolean;
  hotelsScreen: boolean;
};

export function MobileHeaderAd({ showAd, hotelsScreen }: MobileHeaderAdProps) {
  return (
    <Wrapper showAd={showAd} test-id="mobile-header-ad">
      <DisplayAd
        slotConfigId="mobileHeader"
        key={hotelsScreen ? "Hotels" : undefined}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div<{ showAd: boolean }>`
  display: ${(props) => (props.showAd ? "flex" : "none")};
  top: 0;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spacing.xl};
  background-color: ${color.n10};
`;
