import { useEffect, useState } from "react";
import { MobileHeaderAd } from "src/components/MobileHeaderAd/MobileHeaderAd";
import { useFeature } from "src/feature/useFeature";
import { useLayout } from "src/utils/hooks/useLayout";
import { useIsHotelsUrlDeeplink } from "src/utils/hooks/useNavigateToHotelsPage";
import { useScreenMinWidth } from "src/utils/hooks/useScreenMinWidth";
import type { ReturnStage } from "src/utils/hooks/useTypedLocation";
import { useScroll } from "../../ScrollContext";
import type { SearchResponse } from "../../api/SearchResponse";
import { DefaultErrorBoundary } from "../../components/DefaultErrorBoundary/DefaultErrorBoundary";
import { useIsRouteSkipped } from "../../utils/hooks/useIsRouteSkipped";
import { useIsTripScreen } from "../../utils/hooks/useIsTripScreen";
import { useRouteCanonical, useRouteIndex } from "../../utils/hooks/useRoute";
import useSearch from "../../utils/hooks/useSearch";
import { useRouteSegmentIndex } from "../../utils/hooks/useSegment";
import LazyHotelsScreen from "../HotelsScreen/LazyHotelsScreen";
import { RouteScreen } from "../RouteScreen/RouteScreen";
import LazySegmentScreen from "../SegmentScreen/LazySegmentScreen";
import { TransportScreen } from "../TransportScreen/TransportScreen";
import { TripPlannerScreen } from "../TripPlanner/TripPlannerScreen";

// These are also used as identifiers in 'useAnalyticsCoreExperience'
export type Screen =
  | "Segment"
  | "Route"
  | "Search Results"
  | "Hotels"
  | "TripPlanner";

// Encodes the "Hierarchy" of the screens, used for determining which "direction" of user flow we are going
const screenHierarchy: Screen[] = [
  "Search Results",
  "Route",
  "Segment",
  "Hotels",
];

type Props = {
  context: "transport" | "tripPlanner";
  openScheduleIndex?: number;
  returnsFlowLocation?: ReturnStage;
};

export function SearchScreen({
  openScheduleIndex,
  returnsFlowLocation,
}: Props) {
  const layout = useLayout();
  const { searchResponse } = useSearch();
  const routeIndex = useRouteIndex();
  const isRouteSkipped = useIsRouteSkipped(routeIndex);
  const routeCanonical = useRouteCanonical();
  const segmentIndex = useRouteSegmentIndex();
  const isTripScreen = useIsTripScreen();
  const isHotelsScreen = useIsHotelsUrlDeeplink();
  const { isDesktop } = useScreenMinWidth();

  const topOfScreenAdMobile = useFeature("TopOfScreenAdMobile");

  const [screen, setScreen] = useState<Screen>(
    getScreenIndex({
      searchResponse,
      routeCanonical,
      segmentIndex,
      isRouteSkipped,
      isHotel: isHotelsScreen,
      isTripScreen,
      isDesktop,
    })
  );

  const [screenPositions, setScreenPositions] = useState([0]);
  const [isNavigatingBackwards, setIsNavigatingBackwards] = useState(false);
  const { getScrollTop, setScrollTop } = useScroll();

  const newScreen = getScreenIndex({
    searchResponse,
    routeCanonical,
    segmentIndex,
    isRouteSkipped,
    isHotel: isHotelsScreen,
    isTripScreen,
    isDesktop,
  });

  if (newScreen !== screen) {
    // Updating `screenIndex` will trigger the transition and tell it which screen to show.
    setScreen(newScreen);

    const newScreenIndex = screenHierarchy.indexOf(newScreen);
    const screenIndex = screenHierarchy.indexOf(screen);
    setIsNavigatingBackwards(newScreenIndex < screenIndex);

    const screenPositionsCopy = [...screenPositions];
    screenPositionsCopy[screenIndex] = getScrollTop();
    setScreenPositions(screenPositionsCopy);
  }

  const screensWithTopAd: Screen[] = [
    "Segment",
    "Route",
    "Search Results",
    ...(topOfScreenAdMobile ? (["Hotels"] as Screen[]) : []),
  ];

  useEffect(() => {
    setScrollTop(0);
  }, [searchResponse, setScrollTop]);

  useEffect(() => {
    if (isNavigatingBackwards) {
      const screenIndex = screenHierarchy.indexOf(screen);
      setScrollTop(screenPositions[screenIndex]);
    } else {
      setScrollTop(0);
    }
  }, [screen, isNavigatingBackwards, setScrollTop, screenPositions]);

  const StaticScreen = screens[screen];
  return (
    <DefaultErrorBoundary defaultMessage>
      {layout === "mobile" && screen !== "TripPlanner" && (
        <MobileHeaderAd
          showAd={screensWithTopAd.includes(screen)}
          hotelsScreen={topOfScreenAdMobile && screen === "Hotels"}
        />
      )}
      <StaticScreen
        context="transport"
        openScheduleIndex={openScheduleIndex}
        returnsFlowLocation={returnsFlowLocation}
      />
    </DefaultErrorBoundary>
  );
}

const screens: { [key in Screen]: (props: Props) => JSX.Element } = {
  "Search Results": TransportScreen,
  Route: RouteScreen,
  Segment: LazySegmentScreen,
  Hotels: LazyHotelsScreen,
  TripPlanner: TripPlannerScreen,
};

export function getScreenIndex({
  searchResponse,
  routeCanonical,
  segmentIndex,
  isRouteSkipped,
  isHotel,
  isTripScreen,
  isDesktop,
}: {
  searchResponse?: SearchResponse;
  routeCanonical?: string;
  segmentIndex?: number;
  isRouteSkipped?: boolean;
  isHotel?: boolean;
  isTripScreen?: boolean;
  isDesktop?: boolean;
}): Screen {
  if (isHotel) {
    return "Hotels";
  }
  if (isTripScreen) {
    return "TripPlanner";
  }
  if (isDesktop) {
    return "Search Results";
  }
  if (segmentIndex !== undefined) {
    // Show segment screen
    return "Segment";
  }

  if (!routeCanonical) {
    return "Search Results";
  }

  if (searchResponse === undefined) {
    // Looking for Route but no searchResponse; RouteLoading
    return "Route"; // RouteScreen will catch undefined searchResponse
  }

  if (isRouteSkipped) {
    // If the route is skipped we go straight to the segment screen which
    // will default to the first segment but displaying full schedules.
    return "Segment";
  }

  // RouteScreen
  return "Route";
}
