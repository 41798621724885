import { useState } from "react";
import { DisplayAd } from "src/components/DisplayAd/DisplayAd";
import { border_radius } from "src/design-system/tokens/border";
import { useFeature } from "src/feature/useFeature";
import { color as oldColor, spacing } from "src/theme";
import color from "src/design-system/tokens/color";
import { mobileLayout } from "src/utils/hooks/useLayout";
import styled from "styled-components";

export function TripInlineSerpAd() {
  const [hasAd, setHasAd] = useState(false);
  const removeAdPadding = useFeature("InlineSerpNoPadding");

  return (
    <InlineAdWrapper
      test-id="desktop-inline-serp-ad"
      hasAd={hasAd}
      noPadding={removeAdPadding}
    >
      <StyledDisplayAd
        onFilled={() => setHasAd(true)}
        slotConfigId="desktopInlineSerpAd"
      />
    </InlineAdWrapper>
  );
}

const InlineAdWrapper = styled.div<{ hasAd: boolean; noPadding: boolean }>`
  display: ${({ hasAd }) => (hasAd ? "flex" : "none")};
  background-color: ${oldColor.white};
  padding: ${({ noPadding }) => (noPadding ? "0" : "0 6px 6px 15px")};
  margin-top: ${spacing.md};
  border: 1px solid ${oldColor.n30};
  border-left: 1px solid ${oldColor.n30};
  border-radius: ${border_radius.rounded_md};
  overflow: hidden;

  cursor: pointer;

  &:hover {
    // Note: hover effect cannot be applied on GAM ads, only the container
    background: ${color.bg.fill.hover};
  }
  ${mobileLayout} {
    margin-bottom: ${spacing.md};
  }
`;

const StyledDisplayAd = styled(DisplayAd)`
  width: 100%;
`;
