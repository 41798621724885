import { ErrorBoundary } from "@sentry/react";
import { useState } from "react";
import { useIntl } from "react-intl";
import type { GeocodedPlace } from "src/PrefetchData";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { Icon } from "src/components/Icon/Icon";
import { TripPlannerPlaceTitle } from "src/components/TripPlanner/Headings/PlaceTitle";
import { MoreOptionsButton } from "src/components/TripPlanner/MoreOptionsButton/MoreOptionsButton";
import {
  TripCardInputHeading,
  LoadingHeading,
} from "src/components/TripPlanner/TripCard/TripCardHeading/TripCardInputHeading";
import { useFeature } from "src/feature/useFeature";
import { ChevronRightBold } from "src/svg/ChevronRightBold";
import styled from "styled-components";
import {
  useNavigateToHotelsPage,
  useNavigateToTripsHotels,
} from "src/utils/hooks/useNavigateToHotelsPage";

import { desktopLayout, useLayout } from "src/utils/hooks/useLayout";
import SimpleRouteCardLoading from "src/components/TripPlanner/TripCard/SimpleRouteCard/SimpleRouteCardLoading";
import { DragOverlay, useDndMonitor } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { autocompleteToGeocodedPlace } from "src/utils/autocompleteToGeocodedPlace";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import { useApiConfig } from "src/api/ApiConfigProvider";
import { useQueryClient } from "@tanstack/react-query";
import type { AutocompletePlace } from "src/api/AutocompleteResponse";
import { useExitOnHExEntry } from "src/domain/HotelsScreen/utils-exit-to-provider";
import { color, fontSize, fontWeight, spacing } from "../../../theme";
import { useTripPlannerContext } from "../hooks/useTripPlannerContext";
import { createTransportKey } from "../util/createTransportKey";
import { TripPlannerAutocomplete } from "../TripPlannerAutocomplete/TripPlannerAutocomplete";
import messages from "./TripPlannerCard.messages";
import { TripPlannerTravelBlock } from "./TripPlannerTravelBlock";
import ErrorMessage from "./ErrorMessage";

export type TripPlannerCardCallbacks = {
  hoverCallback?: VoidFunction;
};

type TripPlannerCardProps = {
  places: GeocodedPlace[];
  index: number;
  callbacks: TripPlannerCardCallbacks;
  scrollRef?: React.RefObject<HTMLDivElement>;
  isUpdated?: Boolean;
  startDate?: string;
};

export function TripPlannerCard({
  places,
  index,
  callbacks,
  scrollRef,
  isUpdated,
}: TripPlannerCardProps) {
  const { dispatch } = useTripPlannerContext();
  const place = places[index];
  const isLastPlace = index === places.length - 1;
  const nextPlace = !isLastPlace ? places[index + 1] : undefined;
  const canonicalPair = createTransportKey(
    place.canonicalName,
    nextPlace ? nextPlace.canonicalName : ""
  );
  const intl = useIntl();
  const languageCode = localeToLanguageCode(intl.locale);
  const apiConfig = useApiConfig();
  const queryClient = useQueryClient();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  function onSelectDestination(newPlace: AutocompletePlace) {
    setDrawerOpen(false);
    if (newPlace.canonicalName !== place.canonicalName) {
      sendAnalyticsInteractionEvent({
        category: "TripPlanner",
        action: "Select:EditDestinationInline",
        label: newPlace.canonicalName,
      });
      autocompleteToGeocodedPlace(
        newPlace,
        (geocodedPlace: GeocodedPlace) =>
          dispatch({
            type: "EDIT_DESTINATION",
            index,
            newPlace: geocodedPlace,
          }),
        languageCode,
        queryClient,
        apiConfig
      );
    }
  }

  return (
    <>
      {isDrawerOpen && (
        <TripPlannerAutocomplete
          onSelectOption={onSelectDestination}
          handleClose={() => setDrawerOpen(false)}
        />
      )}

      <CardWrapper
        ref={scrollRef}
        onMouseEnter={callbacks.hoverCallback}
        data-testid={`trip-planner-card-${index}`}
        $isUpdated={!!isUpdated}
      >
        <HeaderRow>
          <TripPlannerPlaceTitle
            onClick={() => setDrawerOpen(true)}
            index={index}
          >
            {place.longName ?? place.shortName}
          </TripPlannerPlaceTitle>
          <MoreOptionsButton
            onClickEdit={() => {
              const action = `${
                !isDrawerOpen ? "Open" : "Close"
              }:EditDestination`;
              sendAnalyticsInteractionEvent({
                category: "TripPlanner",
                action: action,
              });
              setDrawerOpen(true);
            }}
            canonicalPair={canonicalPair}
            place={place}
            index={index}
          />
        </HeaderRow>
        {nextPlace !== undefined && (
          <Row>
            <ErrorBoundary fallback={<ErrorMessage />}>
              <TripPlannerTravelBlock
                index={index}
                origin={place}
                destination={nextPlace}
              />
            </ErrorBoundary>
          </Row>
        )}
      </CardWrapper>
    </>
  );
}

type DraggableTripPlannerCardProps = TripPlannerCardProps & {
  place: GeocodedPlace;
  draggableId: string;
  onRemove: () => void;
};

export function DraggableTripPlannerCard(props: DraggableTripPlannerCardProps) {
  const { index, places, place } = props;
  const { tripDestination } = useTripPlannerContext();
  const [isAffectedByDrag, setIsAffectedByDrag] = useState(false);
  const isLastPlace = index === places.length - 1;
  const nextPlace = !isLastPlace ? places[index + 1] : undefined;
  const canonicalPair = createTransportKey(
    place.canonicalName,
    nextPlace ? nextPlace.canonicalName : ""
  );

  const {
    transform,
    transition,
    isDragging,
    listeners,
    attributes,
    setNodeRef,
  } = useSortable({
    id: props.draggableId,
  });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition,
  };

  useDndMonitor({
    onDragOver(event) {
      if (event.over?.id === props.draggableId) {
        setIsAffectedByDrag(true);
      }
    },
    onDragEnd() {
      setIsAffectedByDrag(false);
    },
  });

  return (
    <CardWrapper
      ref={props.scrollRef}
      onMouseOver={props.callbacks.hoverCallback}
      data-testid={`trip-planner-card-${index}`}
      $isDragging={isDragging}
      $isUpdated={!!props.isUpdated}
    >
      <TripCardInputHeading
        geocodedPlace={place}
        index={index}
        canonicalPair={canonicalPair}
        onRemove={props.onRemove}
        dragRef={setNodeRef}
        isDragging={isDragging}
        style={style}
        {...listeners}
        {...attributes}
      />
      <AddHotelCTA {...{ index, places, place }} />
      {isDragging && (
        <DragOverlay>
          <TripCardInputHeading
            geocodedPlace={place}
            index={index}
            canonicalPair={canonicalPair}
            onRemove={props.onRemove}
            id={props.draggableId}
            key={props.draggableId}
            isDragging={false}
            isOverlay
          />
        </DragOverlay>
      )}

      {nextPlace === undefined && tripDestination.destinationCanonical && (
        <SimpleRouteCardLoading />
      )}
      {nextPlace !== undefined && (
        <Row>
          <ErrorBoundary fallback={<ErrorMessage />}>
            <TripPlannerTravelBlock
              isDraggable={true}
              index={index}
              origin={place}
              destination={nextPlace}
              isPendingChanges={isAffectedByDrag}
            />
          </ErrorBoundary>
        </Row>
      )}
    </CardWrapper>
  );
}

type LoadingCardProps = {
  index: number;
  name?: string;
};

export function LoadingCard(props: LoadingCardProps) {
  return (
    <CardWrapper>
      <LoadingHeading {...props} />
    </CardWrapper>
  );
}

type AddHotelCTAProps = {
  index: number;
  place: GeocodedPlace;
  places: GeocodedPlace[];
};

function AddHotelCTA(props: AddHotelCTAProps) {
  const hexIsExpedia = useFeature("ExpediaOnEntry");
  const handleExpediaExit = useExitOnHExEntry("Expedia");

  const { index, places, place } = props;
  const layout = useLayout();
  const isStackedNavigationAccom =
    useFeature("StackedNavigationAccom") && layout === "desktop";

  const originCanonical =
    index === 0 ? place?.canonicalName : places[index - 1]?.canonicalName;

  const intl = useIntl();
  const navigateToTripsHotels = useNavigateToTripsHotels();
  const { navigateToHotels } = useNavigateToHotelsPage();

  function handleAccomClick() {
    sendAnalyticsInteractionEvent({
      category: "SearchResults",
      action: "Click:HotelPromoMultipleDest",
      label: place.canonicalName,
    });

    if (hexIsExpedia && handleExpediaExit) {
      handleExpediaExit({
        lat: place?.lat,
        lng: place?.lng,
        canonicalName: place?.canonicalName,
      });
    } else {
      if (isStackedNavigationAccom) {
        const destinationCanonical =
          index === 0 ? undefined : place.canonicalName;
        navigateToTripsHotels(originCanonical, destinationCanonical);
      } else {
        const destinationCanonical = place.canonicalName;
        navigateToHotels({
          originCanonical,
          destinationCanonical,
        });
      }
    }
  }

  return (
    <AddHotelsCta
      onClick={() => {
        handleAccomClick();
      }}
    >
      {intl.formatMessage(messages.viewHotels)}
      <Icon size="sm-1" data-testid="chevron">
        <ChevronRightBold tint="pink" />
      </Icon>
    </AddHotelsCta>
  );
}

const HeaderRow = styled.div`
  padding: ${spacing.xs} 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Row = styled.div`
  padding: ${spacing.xs} 0;
`;

const CardWrapper = styled.div<{
  $isUpdated?: boolean;
  $isDragging?: boolean;
}>`
  transition: transform 0.3s ease;
  ${({ $isDragging }) => $isDragging && `pointer-events: none; `}
  padding: ${spacing.sm} 0 0;
  ${desktopLayout} {
    margin-left: -14px;
    padding: ${spacing.lg} 0 ${spacing.xs};
  }
`;

// Hotels in Trip Planner Experiment 1

const TypographyBody = styled.div`
  font-weight: ${fontWeight.normal};
  font-size: ${fontSize.body};
  color: ${color.cod};
`;

const AddHotelsCta = styled(TypographyBody)`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;

  gap: ${spacing.md};
  margin: -${spacing.md} 0 0 ${spacing.md};
  margin-right: ${spacing.xxl};
  padding-left: ${spacing.md};
  padding-bottom: ${spacing.sm};

  font-size: ${fontSize.md};
  font-weight: ${fontWeight.medium};
  color: ${color.pink};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;
