import { useRef, useState } from "react";
import type { AutocompletePlace } from "src/api/AutocompleteResponse";
import { useSearchAutocomplete } from "src/domain/LargeSearchBar/useSearchAutocomplete";
import { useUpdateDisplayValueOnGeocode } from "src/domain/LargeSearchBar/useUpdateDisplayValueOnGeocode";
import { defaultFocusedElement, type FocusedElement } from "src/FocusContext";
import type { Geocoded } from "src/PrefetchData";
import { getAutocompleteHint } from "../getAutocompleteHint";
import { modulo } from "../modulo";
import type { SearchPlace } from "./useSearch";

const DEFAULT_MAX_RESULTS = 5;

type SearchConfig = {
  id: string;
  place?: Geocoded | SearchPlace;
  initialQuery?: string;
  onSelect: (place: AutocompletePlace) => void;
  onAfterSelect?: () => void;
  maxNumResults?: number;
};

export function useSearchInput(
  { maxNumResults = DEFAULT_MAX_RESULTS, ...config }: SearchConfig,
  keyboard: ReturnType<typeof useSearchKeyboard>
) {
  const {
    focusedElement,
    setFocusedElement,
    justPressedBackspace,
    setJustPressedBackspace,
  } = keyboard;

  const searchState = useSearchHint({
    place: config.place,
    initialQuery: config.initialQuery,
    focusedElement,
    inputId: config.id,
    setFocusedElement,
    justPressedBackspace,
    setJustPressedBackspace,
    maxNumResults,
    onEnter: (place) => {
      config.onSelect(place);
      config.onAfterSelect?.();
    },
  });

  return searchState;
}

export function useSearchHint({
  place,
  initialQuery,
  onEnter,
  inputId,
  focusedElement,
  setFocusedElement,
  justPressedBackspace,
  setJustPressedBackspace,
  maxNumResults,
}: {
  place: Geocoded | SearchPlace | undefined;
  initialQuery?: string;
  onEnter: (place: AutocompletePlace) => void;
  inputId: string;
  focusedElement: FocusedElement;
  setFocusedElement: (
    newFocusedElement: FocusedElement,
    numResults: number
  ) => void;
  justPressedBackspace: boolean;
  setJustPressedBackspace: React.Dispatch<React.SetStateAction<boolean>>;
  maxNumResults: number;
}) {
  const autocompleteState = useSearchAutocomplete(
    place?.longName ?? initialQuery ?? ""
  );
  const [isResultsClosed, setIsResultsClosed] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  useUpdateDisplayValueOnGeocode({
    currentSearchLongName: place?.longName ?? "",
    updateDisplayValue: autocompleteState.changeDisplayValueWithoutResults,
  });

  const { results } = autocompleteState;
  const longName = results[0]?.longName;

  const isInputFocused = focusedElement.index === 0;
  const isDropdownFocused =
    focusedElement.id === inputId && focusedElement.index > 0;
  const hint =
    justPressedBackspace || isDropdownFocused
      ? ""
      : getAutocompleteHint(autocompleteState.displayValue, longName);

  const displayValue =
    (isDropdownFocused
      ? results[focusedElement.index - 1]?.longName
      : autocompleteState.displayValue) ?? "";

  function handleSetIsResultsClosed(isResultsClosed: boolean) {
    if (isResultsClosed) {
      setFocusedElement({ id: "none", index: 0 }, 0);
    }
    setIsResultsClosed(isResultsClosed);
  }

  function handleQueryChange(query: string) {
    autocompleteState.changeDisplayValueWithResults(query);

    setIsResultsClosed(false);
    // Reset the focused autocomplete dropdown element if the user continues to type into the
    // search input while cycling through the autocomplete dropdown list.
    setFocusedElement({ id: inputId, index: 0 }, results.length);
  }

  function handleEnter(event: React.KeyboardEvent<HTMLInputElement>) {
    if (!results.length) {
      return;
    }
    event.preventDefault();
    const focusedResult =
      results[isInputFocused ? 0 : focusedElement.index - 1];
    handleSetIsResultsClosed(true);
    autocompleteState.changeDisplayValueWithoutResults(
      focusedResult.longName ?? focusedResult.shortName
    );
    onEnter(focusedResult);
    setJustPressedBackspace(false);
  }

  const displayResults = isResultsClosed ? [] : results.slice(0, maxNumResults);

  return {
    displayValue,
    displayResults,
    autocompleteState,
    hint,
    handleEnter,
    handleQueryChange,
    inputRef,
    isResultsClosed,
    setIsResultsClosed: handleSetIsResultsClosed,
  };
}

export function useSearchKeyboard(maxNumResults = DEFAULT_MAX_RESULTS) {
  const [focusedElement, setFocusedElement] = useState<FocusedElement>(
    defaultFocusedElement
  );
  // We need to do capture this so we know to stop trying to show a hint each time
  // a character is removed until the user starts typing again.
  const [justPressedBackspace, setJustPressedBackspace] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const focusedElementRef = useRef<HTMLLIElement>(null);
  const isInputFocused = focusedElement.index === 0;

  function handleSetFocusedElement(
    element: FocusedElement,
    numResults: number
  ) {
    let index = element.index;
    index = modulo(index, Math.min(numResults, maxNumResults) + 1);
    setFocusedElement({
      id: element.id,
      index: Number.isNaN(index) ? 0 : index,
    });
  }

  function onKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>,
    handleEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    isResultsClosed: boolean,
    numResults: number
  ) {
    switch (event.key) {
      case "Backspace":
      case "Delete":
      case "ArrowLeft":
        setJustPressedBackspace(true);
        break;
      case "ArrowDown":
        handleSetFocusedElement(
          {
            ...focusedElement,
            index: focusedElement.index + 1,
          },
          numResults
        );
        break;
      case "ArrowUp":
        handleSetFocusedElement(
          {
            ...focusedElement,
            index: focusedElement.index - 1,
          },
          numResults
        );
        // Prevent the cursor position from shifting to the start of the input.
        event.preventDefault();
        break;
      case "Enter":
        handleEnter(event);
        break;
      case "Tab":
        if (isInputFocused) break;
        handleEnter(event);
        break;
      case "ArrowRight": {
        if (!justPressedBackspace && !isResultsClosed) {
          handleEnter(event);
        }
        break;
      }
      default:
        setJustPressedBackspace(false);
    }
  }

  return {
    onKeyDown,
    focusedElement,
    setFocusedElement: handleSetFocusedElement,
    justPressedBackspace,
    setJustPressedBackspace,
    scrollContainerRef,
    focusedElementRef,
  };
}
