import {
  type Dispatch,
  type PropsWithChildren,
  type RefObject,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { addDays } from "src/utils/dateTime";
import { useHotelDestinationFromUrl } from "src/utils/hooks/useHotelDestinationFromUrl";
import type { Place } from "src/api/SearchResponse";
import type { Geocoded } from "src/PrefetchData";
import type { SearchPlace } from "src/utils/hooks/useSearch";
import { useFeature } from "src/feature/useFeature";
import {
  DEFAULT_MAP_RADIUS,
  DEFAULT_MAP_ZOOM,
} from "../../components/Map/constants";
import {
  type HotelsFilterAction,
  type HotelsFilterState,
  defaultFilterState,
  filtersReducer,
} from "./filtersReducer";
import { DEFAULT_ROOM } from "./HotelSearch/constants";
import { isRefineSearchChildPlace } from "./utils";

export type DateCombos = [Date, Date] | undefined;
export type Child = {
  age?: number;
};

export type RoomDetail = {
  adults: number;
  children: Child[];
};

export type TargetHotelPin = {
  hoveredId?: string;
  focusedId?: string;
};

export type RefineSearchData = {
  lat: number;
  lng: number;
  label: string;
};

export type HotelListQueryParams = {
  // Note: When undefined, the destination lat/lng are used
  lat: number | undefined;
  lng: number | undefined;
  enabled: boolean;
  zoom: number;
  radius: number;
  arrivalDate?: Date;
  departureDate?: Date;
  roomDetails: RoomDetail[];
  filters?: HotelsFilterState;
};

// Default Dates
const DEFAULT_STAY_LENGTH = 1;

const tonight = new Date();
tonight.setHours(0, 0, 0, 0);

const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow.setHours(0, 0, 0, 0);

export const TONIGHT_DATE = tonight;
export const TOMORROW_DATE = tomorrow;

export type HotelsContextProps = {
  destination?: SearchPlace | Place | Geocoded | undefined;
  stayDatesUI: DateCombos;
  setStayDatesUI: Dispatch<SetStateAction<DateCombos>>;
  roomDetailsUI: RoomDetail[];
  setRoomDetailsUI: Dispatch<SetStateAction<RoomDetail[]>>;
  showMap: boolean;
  setShowMap: Dispatch<SetStateAction<boolean>>;
  targetPinHotelId: TargetHotelPin;
  setTargetPinHotelId: Dispatch<SetStateAction<TargetHotelPin>>;
  hotelListQueryParams: HotelListQueryParams;
  setHotelListQueryParams: Dispatch<SetStateAction<HotelListQueryParams>>;
  sortOption: HotelSortOption;
  setSortOption: Dispatch<HotelSortOption>;
  filtersState: HotelsFilterState;
  dispatchFilters: Dispatch<HotelsFilterAction>;
  // route is from transport destination -> hotel.
  focusedRouteIndex: number | undefined;
  setFocusedRouteIndex: Dispatch<SetStateAction<number | undefined>>;
  isFakeHotelLoad: boolean;
  initiateFakeHotelLoad: () => void;
  isDatePickerOpen: boolean;
  setIsDatePickerOpen: Dispatch<SetStateAction<boolean>>;
  datePickerRef: RefObject<HTMLButtonElement>;
  setFocusToDatePicker: () => void;
  showDatesPopover: boolean;
  setShowDatesPopover: Dispatch<SetStateAction<boolean>>;
  refineSearchData: RefineSearchData | null;
  setRefineSearchData: Dispatch<SetStateAction<RefineSearchData | null>>;
};

export const HotelsContext = createContext<HotelsContextProps | undefined>(
  undefined
);

export function HotelsProvider(
  props: PropsWithChildren<{ override?: Partial<HotelsContextProps> }>
) {
  const hexRefineSearchKind = useFeature("HExRefineSearchKind");
  const hexRefineSearchFeature =
    hexRefineSearchKind && hexRefineSearchKind !== "baseline";

  // TODO: Eventually all information for getting the destination *should* be in the URL and useSearch(). Currently, we also have
  // to rely on Return Flow State, which tells us whether to swap origin and destination.
  const destination = useHotelDestinationFromUrl();
  const urlParamDates: DateCombos = getUrlParamDates();
  const defaultDates: DateCombos = undefined;

  const [filtersState, dispatchFilters] = useReducer(
    filtersReducer,
    defaultFilterState
  );

  // Date range for hotel booking availability search.
  // Note: undefined means that the ui should fallback to static prices
  const [stayDatesUI, setStayDatesUI] = useState<DateCombos>(
    urlParamDates ?? defaultDates
  );

  // Details for add/remove room/guest details of a hotel booking availability search.
  const [roomDetailsUI, setRoomDetailsUI] = useState<RoomDetail[]>([
    DEFAULT_ROOM,
  ]);

  // This is used on mobile to indicate whether the map is currently being displayed.
  const [showMap, setShowMap] = useState<boolean>(false);
  const [targetPinHotelId, setTargetPinHotelId] = useState<TargetHotelPin>({
    hoveredId: undefined,
    focusedId: undefined,
  });

  // Part of refine search experiment. Stores coords to show a special pin or overlay, and a radius for the overlay.
  const [refineSearchData, setRefineSearchData] =
    useState<RefineSearchData | null>(null);

  /** The arrivalDate, departureDate and roomDetails within hotelListQueryParams
   * are a snapshot of what the stayDates and roomDetails state were
   * when the search button was pressed */
  const defaultHotelListQueryParams = {
    lat: undefined,
    lng: undefined,
    enabled: true,
    zoom: DEFAULT_MAP_ZOOM,
    radius: DEFAULT_MAP_RADIUS,
    arrivalDate: defaultDates?.[0],
    departureDate: defaultDates?.[1],
    roomDetails: [DEFAULT_ROOM],
    filters: defaultFilterState,
    facilityCodes: [],
    hotelTypeCodes: [],
  };
  const [hotelListQueryParams, setHotelListQueryParams] =
    useState<HotelListQueryParams>(defaultHotelListQueryParams);

  // When the destination changes, reset map-related params
  // Note: Ideally this should not be an effect and should be done at the source when the destination is changed.
  useEffect(() => {
    // Only scenario when we allow a tight search is for a RefineSearch location.
    const isTightSearch =
      hexRefineSearchFeature &&
      destination?.canonicalName &&
      isRefineSearchChildPlace(destination.canonicalName);

    const tightSearchRadius = 2; // km.

    const searchRadius = isTightSearch ? tightSearchRadius : DEFAULT_MAP_RADIUS;

    if (searchRadius) {
      setHotelListQueryParams((state) => ({
        ...state,
        lat: undefined,
        lng: undefined,
        zoom: DEFAULT_MAP_ZOOM,
        radius: searchRadius,
      }));
    } else {
      setHotelListQueryParams((state) => ({
        ...state,
        lat: undefined,
        lng: undefined,
        zoom: DEFAULT_MAP_ZOOM,
      }));
    }
  }, [
    hexRefineSearchFeature,
    destination?.lat,
    destination?.lng,
    destination?.canonicalName,
  ]);

  const [sortOption, setSortOption] = useState<HotelSortOption>("default");

  const [focusedRouteIndex, setFocusedRouteIndex] = useState<number>();

  const [isFakeHotelLoad, setIsFakeHotelLoad] = useState(false);
  const initiateFakeHotelLoad = () => {
    setIsFakeHotelLoad(true);
    setTimeout(() => {
      setIsFakeHotelLoad(false);
    }, 500);
  };

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const datePickerRef = useRef<HTMLButtonElement>(null);

  const [showDatesPopover, setShowDatesPopover] = useState(false);

  const setFocusToDatePicker = () => {
    if (datePickerRef.current) {
      setIsDatePickerOpen(true);
      datePickerRef.current.focus();
    }
  };

  return (
    <HotelsContext.Provider
      value={{
        destination,
        stayDatesUI,
        setStayDatesUI,
        roomDetailsUI,
        setRoomDetailsUI,
        showMap,
        setShowMap,
        targetPinHotelId,
        setTargetPinHotelId,
        hotelListQueryParams,
        setHotelListQueryParams,
        sortOption,
        setSortOption,
        filtersState,
        dispatchFilters,
        focusedRouteIndex,
        setFocusedRouteIndex,
        isFakeHotelLoad,
        initiateFakeHotelLoad,
        isDatePickerOpen,
        setIsDatePickerOpen,
        datePickerRef,
        setFocusToDatePicker,
        showDatesPopover,
        setShowDatesPopover,
        refineSearchData,
        setRefineSearchData,
        ...props.override,
      }}
      {...props}
    />
  );
}

export function useHotelsContext() {
  const context = useContext(HotelsContext);
  if (!context) {
    throw new Error(`useHotelsContext must be used within HotelsProvider`);
  }
  return context;
}

export function getDefaultCheckInDate() {
  return TONIGHT_DATE;
}

export function getDefaultCheckOutDate(checkInDate: Date) {
  return addDays(checkInDate, DEFAULT_STAY_LENGTH);
}

function getUrlParamDates(): DateCombos {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const urlHotelCheckInString = urlParams.get("hotelCheckInDate");
  const urlHotelCheckOutString = urlParams.get("hotelCheckOutDate");

  const urlHotelCheckInDate = urlHotelCheckInString
    ? new Date(urlHotelCheckInString)
    : undefined;
  urlHotelCheckInDate?.setHours(0, 0, 0, 0);

  if (!urlHotelCheckInDate) {
    return undefined;
  }

  const urlHotelCheckOutDate = urlHotelCheckOutString
    ? new Date(urlHotelCheckOutString)
    : undefined;
  urlHotelCheckOutDate?.setHours(23, 59, 59, 0);

  if (urlHotelCheckOutDate && urlHotelCheckOutDate > urlHotelCheckInDate) {
    return [urlHotelCheckInDate, urlHotelCheckOutDate];
  } else {
    return [
      urlHotelCheckInDate,
      addDays(urlHotelCheckInDate, DEFAULT_STAY_LENGTH),
    ];
  }
}
export type HotelSortOption =
  | "priceLow"
  | "priceHigh"
  | "reviewHigh"
  | "default";
