import { useFeature } from "../../feature/useFeature";
import { isRouteSkippable } from "../isRouteSkippable";
import useSearch from "./useSearch";

export function useIsRouteSkipped(routeIndex?: number) {
  const { searchResponse } = useSearch();
  const shouldSkipRoutes = useFeature("SkipRoutes") === "skip";

  return (
    shouldSkipRoutes &&
    !!searchResponse &&
    routeIndex !== undefined &&
    isRouteSkippable(searchResponse, routeIndex)
  );
}
