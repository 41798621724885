import React, { type FC, type SyntheticEvent, useEffect } from "react";
import type { AutocompletePlace } from "src/api/AutocompleteResponse";
import { BlurAwayListener } from "src/components/BlurAwayListener/BlurAwayListener";
import { ClickAwayListener } from "src/components/ClickAwayListener/ClickAwayListener";
import type { AutocompleteKind } from "src/domain/AutocompleteScreen/AutocompleteScreen";
import { useTripPlannerContext } from "src/domain/TripPlanner/hooks/useTripPlannerContext";
import { getScreenKey } from "src/domain/TripPlanner/util/getScreenKey";
import { getLastTripPlannerPlace } from "src/domain/TripPlanner/util/getTripPlannerPlace";
import { useFeature } from "src/feature/useFeature";
import { FocusContext, type FocusedElement } from "src/FocusContext";
import type { GeocodedPlace } from "src/PrefetchData";
import {
  useSearchInput,
  useSearchKeyboard,
} from "src/utils/hooks/useSearchInput";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import styled, { css } from "styled-components";
import { AutocompleteInput } from "../../../AutocompleteInput/AutocompleteInput";
import { AutocompleteList } from "../../../AutocompleteList/AutocompleteList";

export function AddDestinationInput(
  props: Readonly<{
    onSelectOption: (place: AutocompletePlace) => void;
    isResetOnSelect?: boolean;
    id: string;
    type: AutocompleteKind;
    onBlur?: () => void;
    floatingList?: boolean;
    onFocus?: (e: SyntheticEvent) => void;
    onChange?: (query: string) => void;
    disabled?: boolean;
    autoFocus?: boolean;
    AutocompleteListWrapper?: FC<React.PropsWithChildren<unknown>>;
    initialValue?: string;
    solidBackground?: boolean;
    hideList?: boolean;
    placeholder?: string;
    hideIcon?: boolean;
    transparentBackground?: boolean;
    addDestinationRef?: React.RefObject<HTMLInputElement>;
    className?: string;
    notRelative?: boolean;
    icon?: React.ReactNode;
    fullWidth?: boolean;
    curvedBorder?: boolean;
    noShadow?: boolean;
    noBorder?: boolean;
    isFocused?: boolean;
    showClearIcon?: boolean;
    handleClear?: () => void;
    handleClickAway?: () => void;
    onMouseEnter?: () => void;
    onMouseOut?: () => void;
    horizontalSearch?: boolean;
    index?: number;
    filteredPlaces?: GeocodedPlace[];
  }>
) {
  const keyboard = useSearchKeyboard();
  const searchState = useSearchInput(
    {
      id: props.id,
      onSelect: onSelectOption,
      initialQuery: props.initialValue,
    },
    keyboard
  );

  const { AutocompleteListWrapper = DefaultAutocompleteWrapper } = props;
  const { tripPlannerDetails } = useTripPlannerContext();
  const location = useTypedLocation();
  const screenKey = getScreenKey(location.hash);
  const isDestinationRecommendation =
    useFeature("PreDestinationPages") !== "baseline";
  const destinationPlace = getLastTripPlannerPlace(tripPlannerDetails);
  // TODO: once active, this will turn on autocomplete rec
  const isDestinationPageActive = false;
  const isRecommendationsEnabled =
    isDestinationRecommendation &&
    !screenKey &&
    destinationPlace?.countryCode === "IT" &&
    isDestinationPageActive;
  const isHSBDestinationInput = ![
    "add-destination-inline-0",
    "add-destination-inline",
    "add-destination-modal",
  ].includes(props.id);

  let results: AutocompletePlace[] = searchState.displayResults;

  const { filteredPlaces } = props;
  if (filteredPlaces?.length) {
    results = results.filter(
      (result) =>
        !filteredPlaces.some(
          (place) =>
            place.canonicalName === result.canonicalName ||
            place.longName === result.longName // To catch places with multiple canonical names (e.g Berlin, Berlin-Germany)
        )
    );
  }

  const italyRecommendation: AutocompletePlace = {
    kind: "country",
    shortName: "Italy",
    canonicalName: "Italy",
    longName: "Explore recommendations for Italy",
    link: "/discover/italy",
  };
  if (isRecommendationsEnabled && isHSBDestinationInput) {
    results.push(italyRecommendation);
  }

  function onSelectOption(place: AutocompletePlace) {
    if (props.isResetOnSelect) {
      resetInput();
    }
    props.onSelectOption(place);
  }

  function onChangeCallback(query: string) {
    searchState.handleQueryChange(query);
    if (props.onChange) {
      props.onChange(query);
    }
  }

  function resetInput() {
    searchState.autocompleteState.changeDisplayValueWithoutResults(
      props.initialValue ?? ""
    );
  }

  function handleClickAway() {
    resetInput();
    searchState.setIsResultsClosed(true);
    props.handleClickAway?.();
  }

  function handleBlur() {
    resetInput();
    searchState.setIsResultsClosed(true);
    props.onBlur?.();
  }

  // Prefill input on load
  useEffect(() => {
    if (props.initialValue) {
      searchState.autocompleteState.changeDisplayValueWithoutResults(
        props.initialValue
      );
    }
    // Ignore exhaustive deps because we only want to run this if an input is
    // empty and another destination is added.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripPlannerDetails.places]);

  function onFocusChanged(newFocusedElement: FocusedElement) {
    keyboard.setFocusedElement(
      newFocusedElement,
      searchState.displayResults.length
    );
  }

  function handleKeydown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (keyboard.focusedElement.index === 0 && event.key === "Tab") {
      return resetInput();
    }
    keyboard.onKeyDown(
      event,
      searchState.handleEnter,
      searchState.isResultsClosed,
      searchState.displayResults.length
    );
  }

  return (
    <FocusContext.Provider
      value={{
        focusedElement: keyboard.focusedElement,
        onFocusChanged,
      }}
    >
      <InputWrapper notRelative={props.notRelative} fullWidth={props.fullWidth}>
        <AutocompleteInput
          type={props.type}
          value={!props.disabled ? searchState.displayValue : ""}
          hint={searchState.hint}
          onChange={onChangeCallback}
          id={props.id}
          disabled={props.disabled}
          onFocus={props.onFocus}
          solidBackground={props.solidBackground}
          icon={props.icon}
          onKeyDown={handleKeydown}
          // We can disable this autoFocus rule because we only ever have one instance
          // in the DOM at any one time.
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={props.autoFocus}
          placeholder={props.placeholder}
          transparentBackground={props.transparentBackground}
          noShadow={props.noShadow}
          noBorder={props.noBorder}
          className={props.className}
          addDestinationRef={props.addDestinationRef}
          fullWidth={props.fullWidth}
          curvedBorder={props.curvedBorder}
          isFocused={props.isFocused}
          handleClear={props.handleClear}
          showClearIcon={!!searchState.displayValue && props.showClearIcon}
          horizontalSearch={props.horizontalSearch}
          index={props.index}
          isDraggable={props.horizontalSearch}
        />
        <BlurAwayListener onBlurAway={handleBlur}>
          <ClickAwayListener onClickAway={handleClickAway}>
            {props.initialValue === searchState.displayValue ||
              (!props.disabled && (
                <AutocompleteListWrapper ref={keyboard.scrollContainerRef}>
                  <AutocompleteList
                    size="sm"
                    iconSize="xxl"
                    results={searchState.displayResults}
                    onSelectPlace={onSelectOption}
                    focusRef={keyboard.focusedElementRef}
                    isInputFocused={!searchState.isResultsClosed}
                  />
                </AutocompleteListWrapper>
              ))}
          </ClickAwayListener>
        </BlurAwayListener>
      </InputWrapper>
    </FocusContext.Provider>
  );
}

const DefaultAutocompleteWrapper = styled.div``;

const InputWrapper = styled.div<{ notRelative?: boolean; fullWidth?: boolean }>`
  position: ${(props) => !props.notRelative && "relative"};
  display: block;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;
