import type {
  SearchResponse,
  Place,
  Segment,
  Route,
  PricingDebug,
} from "../../api/SearchResponse";
import { vehicleFromSegment } from "../../utils/adapters/vehicle";
import { transitModeFromVehicleKind } from "../../utils/adapters/transitMode";
import type { Mode } from "../../utils/types/mode";
import {
  type PriceRange,
  displayPriceRangeFromIndicativePrices,
  getDerivationPrice,
} from "../../utils/adapters/priceRange";
import { generateExitPoint } from "../../analytics/generateExitPoint/generateExitPoint";
import { getCarHireDeeplinkRedirectUrl } from "../../utils/url";
import {
  originPlaceFromSegment,
  originPlaceFromRouteOrSegment,
  destinationPlaceFromRouteOrSegment,
  preferredNameFromPlace,
} from "../../utils/adapters/place";
import { utcOffsetFromPlace } from "../../utils/adapters/utcOffset";
import {
  durationInMinutesFromRoute,
  durationInMinutesFromSegment,
} from "../../utils/adapters/duration";
import {
  distanceFromRoute,
  distanceFromSegment,
} from "../../utils/adapters/distance";
import type { PassengerDetails } from "../../PassengerDetailsProvider";
import type { FeatureConfig } from "../../feature/FeatureConfig";
import type { SpecificViewModelParams } from "./SegmentScreenViewModel";
import {
  type ScheduleCapabilities,
  getCapabilities,
} from "./ScheduleCapabilities";
import {
  type CarrierViewModel,
  carrierAdapter,
} from "./CarrierSection/carrierAdapter";

export type SegmentViewModel = {
  transitMode: Mode;
  origin: {
    name: string;
    code?: string;
  };
  destination: {
    name: string;
    code?: string;
  };
  carriers: CarrierViewModel[];
  priceRange?: PriceRange;
  originUtcOffset: number;
  isSegment: boolean;
  capabilities: ScheduleCapabilities;
  priceDebug?: PricingDebug;
} & SpecificViewModelParams;

// Don't make any props optional, the feature flags here control which schedule requests are used.
export function createSegmentScreenViewModel(
  response: SearchResponse,
  routeIndex: number,
  segmentIndexOfRoute: number | undefined,
  passengerDetails: PassengerDetails | undefined,
  useConnectedSchedules:
    | FeatureConfig["UseStaticConnectedSchedules"]
    | undefined
): SegmentViewModel {
  const route = response.routes[routeIndex];
  const routeSegments = route.segments.map((si) => response.segments[si]);

  // If we are showing a single-segment route, treat it as a route.
  const isSingleSegmentRoute =
    segmentIndexOfRoute === 0 && routeSegments.length === 1;
  const showingSegment =
    segmentIndexOfRoute !== undefined && !isSingleSegmentRoute;

  const segment = showingSegment
    ? routeSegments[segmentIndexOfRoute]
    : undefined;

  let specificParams: SpecificViewModelParams;
  let routeOrSegment: Route | Segment;

  if (showingSegment) {
    specificParams = viewModelFromSegment(
      response,
      routeIndex,
      segmentIndexOfRoute
    );
    routeOrSegment = segment!;
  } else {
    specificParams = viewModelFromRoute(response, routeIndex);
    routeOrSegment = response.routes[routeIndex];
  }

  // The below are the same method of retrieval across route and segment
  const originPlace = originPlaceFromRouteOrSegment(
    response,
    routeIndex,
    segmentIndexOfRoute
  );

  const origin = {
    name: originPlace.shortName,
    code: originPlace.code,
  };

  const destinationPlace = destinationPlaceFromRouteOrSegment(
    response,
    routeIndex,
    segmentIndexOfRoute
  );

  const destination = {
    name: destinationPlace.shortName,
    code: destinationPlace.code,
  };

  const carriers = carrierAdapter(response, routeIndex, segmentIndexOfRoute);

  const priceDebug = getDerivationPrice(routeOrSegment.indicativePrices);

  const originUtcOffset = utcOffsetFromPlace(response, originPlace);

  const firstSegmentKind = vehicleFromSegment(response, routeSegments[0]).kind;
  const lastSegmentKind = vehicleFromSegment(
    response,
    routeSegments[routeSegments.length - 1]
  ).kind;

  const hasFirstOrLastWalkingSegment =
    !showingSegment &&
    routeSegments.length > 1 &&
    (firstSegmentKind === "walk" || lastSegmentKind === "walk");

  const capabilities = getCapabilities(
    route,
    segment,
    showingSegment,
    originUtcOffset,
    specificParams.isFlight,
    // Nasty hack to disallow Jayride live schedules for short term.
    ["shuttle", "towncar", "taxi"].includes(specificParams.transitMode),
    originPlace,
    destinationPlace,
    hasFirstOrLastWalkingSegment,
    useConnectedSchedules ?? "never"
  );

  const priceRange = displayPriceRangeFromIndicativePrices(
    routeOrSegment.indicativePrices,
    passengerDetails,
    capabilities.isBookable
  );

  return {
    ...specificParams,
    origin,
    destination,
    carriers,
    priceRange,
    originUtcOffset,
    isSegment: showingSegment,
    capabilities,
    ...(priceDebug && { priceDebug }),
  };
}

function viewModelFromRoute(
  response: SearchResponse,
  routeIndex: number
): SpecificViewModelParams {
  const route = response.routes[routeIndex];
  const segments = route.segments.map((si) => response.segments[si]);

  // These kinds and modes are 1:1 for segment kinds/modes.
  const kinds = segments.map((s) => vehicleFromSegment(response, s).kind);
  const modes = kinds.map((kind) => transitModeFromVehicleKind(kind));

  const indexOfFirstMajor = segments.findIndex((s) => s.isMajor);

  const duration = durationInMinutesFromRoute(response, route);

  const distance = distanceFromRoute(response, routeIndex);

  // We'll have a car promo for the first eligible segment
  const firstCarHireSegmentIndex = modes.findIndex((mode) =>
    ["walk", "taxi", "rideshare", "car"].includes(mode)
  );

  let carHireTransitMode: Mode = "unknown";
  let carHirePromoUrl: string | undefined;

  if (firstCarHireSegmentIndex !== -1) {
    carHireTransitMode = modes[firstCarHireSegmentIndex];
    carHirePromoUrl = buildCarHireRedirectUrl(
      originPlaceFromSegment(response, firstCarHireSegmentIndex),
      carHireTransitMode,
      response
    );
  }

  const isFlight = modes.some((m) => m === "plane");

  return {
    transitMode: modes[indexOfFirstMajor],
    transitKind: kinds[indexOfFirstMajor],
    isFlight,
    duration,
    distance,
    carHireTransitMode,
    carHirePromoUrl,
  };
}

function viewModelFromSegment(
  response: SearchResponse,
  routeIndex: number,
  segmentIndexOfRoute: number
): SpecificViewModelParams {
  const route = response.routes[routeIndex];
  const segmentIndex = route.segments[segmentIndexOfRoute];
  const segment = response.segments[segmentIndex];

  const vehicle = vehicleFromSegment(response, segment);
  const transitMode = transitModeFromVehicleKind(vehicle.kind);
  const transitKind = vehicle.kind;

  const duration = durationInMinutesFromSegment(response, segment);
  const distance = distanceFromSegment(
    response,
    routeIndex,
    segmentIndexOfRoute
  );

  const originPlace = originPlaceFromSegment(response, segmentIndex);

  const carHirePromoUrl = buildCarHireRedirectUrl(
    originPlace,
    transitMode,
    response
  );

  const isFlight = vehicle.kind === "plane";

  return {
    transitMode,
    transitKind,
    isFlight,
    duration,
    distance,
    carHireTransitMode: transitMode,
    carHirePromoUrl,
  };
}

export function buildCarHireRedirectUrl(
  origin: Place,
  transitMode: Mode,
  searchResponse: SearchResponse
): string | undefined {
  // only show car hire promos on walk, taxi, rideshare, and car segments
  if (!["walk", "taxi", "rideshare", "car"].includes(transitMode)) {
    return undefined;
  }

  const requestId = searchResponse.request.requestId;
  return getCarHireDeeplinkRedirectUrl(
    requestId,
    generateExitPoint("segment", "carCta", "car"),
    preferredNameFromPlace(origin),
    origin.lat,
    origin.lng
  );
}
