import { useCallback, useRef } from "react";
import { useNavigate } from "react-router";
import { encodeSearchParamsForUrl } from "src/utils/encodeSearchParamsForUrl";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import useUser from "src/utils/hooks/useUser";
import { navigateToNewStateHash } from "src/utils/location/navigateToNewStateHash";
import { setSearchParamFromPlaces } from "../../../utils/hooks/useSearchParamPlaces";
import type { TripPlannerDetails } from "../TripPlannerProvider";
import { MAX_SEARCH_PARAM_PLACES } from "./useSwitchURLToTripId";

/** This function:
 * - Updates A-B to reflect trip plan first and second location e.g.`map/Sydney/Melbourne`
 * - Updates search query when tripID is not being used e.g.`search=Sydney,Melbourne`
 */
export default function useUpdatePathForTrip() {
  const location = useTypedLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const updatedPathRef = useRef<string | undefined>(undefined);

  const updateTripPath = useCallback(
    (tripPlannerDetails: TripPlannerDetails) => {
      if (tripPlannerDetails.places.length < 2) {
        return;
      }
      let search: string | undefined = location.search;
      const shouldUpdateTripSearchParam =
        !user?.id &&
        !tripPlannerDetails.slug &&
        tripPlannerDetails.places.length <= MAX_SEARCH_PARAM_PLACES;

      if (shouldUpdateTripSearchParam) {
        const places = tripPlannerDetails.places;
        const searchParams = new URLSearchParams(location.search);
        setSearchParamFromPlaces(searchParams, places);
        search = encodeSearchParamsForUrl(searchParams);
      }
      navigateToNewStateHash(
        navigate,
        {
          tripPlannerDetails,
        },
        "#trips",
        {
          ...location,
          search: search ?? location.search,
        }
      );
    },
    [location, user?.id, navigate]
  );

  return { updateTripPath, updatedPathRef };
}
