import type { ReactNode } from "react";
import { Button } from "src/components/Button/Button";
import { border_radius } from "src/design-system/tokens/border";
import { useFeature } from "src/feature/useFeature";
import { fontSize, spacing } from "src/theme";
import styled, { keyframes } from "styled-components";

type Props = {
  text: string;
  onClick: any;
  leftIcon: ReactNode;
};

export function FloatingButton({ text, onClick, leftIcon }: Props) {
  const hexScrollNudgeFeature = useFeature("HExScrollNudge");

  return (
    <ButtonWrapper
      onClick={onClick}
      textColor="primaryOnDark"
      backgroundColor={hexScrollNudgeFeature ? "accomBlue" : "n300"}
      leftIcon={leftIcon}
      leftIconSpacing="md"
      $hexScrollNudgeFeature={hexScrollNudgeFeature}
      id="hex-map-button"
    >
      <ButtonText>{text}</ButtonText>
    </ButtonWrapper>
  );
}

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
`;

const ButtonWrapper = styled(Button)<{ $hexScrollNudgeFeature: boolean }>`
  width: fit-content;
  height: ${spacing.xxxl};
  padding: ${spacing.md} ${spacing.xl};
  border-radius: ${border_radius.rounded_xxl};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  min-height: ${spacing.xxxl};
  position: absolute;
  left: 50%;
  bottom: 54px; // 54px is the height of the bottom navbar
  margin-bottom: ${spacing.xl};
  transform: translateX(-50%);
  min-width: 90px;
  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }

  animation: ${fadeInUp} 0.35s ease-out 0.15s backwards;
`;

const ButtonText = styled.p`
  font-size: ${fontSize.body};
`;
