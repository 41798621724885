import { useReducedMotion } from "@react-spring/web";
import { initializeTcDataAnalyticsLogging } from "@rome2rio/tcf-api";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useCallback, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { sendAnalyticsNonInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import type { AnalyticsEventCategory } from "./analytics/analyticsEventTypes";
import type { View } from "./analytics/generateExitPoint/generateExitPoint";
import messages from "./App.messages";
import { useInitializeAppleSignin } from "./auth/sdks/AppleAuthSdk";
import { useInitializeGoogleSignin } from "./auth/sdks/GoogleAuthSdkHooks";
import { SkipLink } from "./components/AccessibilityLink/SkipLink";
import { BottomNavbar } from "./components/BottomNavbar/BottomNavbar";
import { DefaultErrorBoundary } from "./components/DefaultErrorBoundary/DefaultErrorBoundary";
import { Layout } from "./components/Layout/Layout";
import { RightRail } from "./components/RightRail/RightRail";
import { VisuallyHidden } from "./components/VisuallyHidden/VisuallyHidden";
import { registerAdaraConsent } from "./data-partners/adara/registerAdaraConsent";
import { registerCrimtanConsent } from "./data-partners/addCrimtan";
import { registerMarketingDataPartners } from "./data-partners/registerDataPartner";
import color from "./design-system/tokens/color";
import type { AutocompleteKind } from "./domain/AutocompleteScreen/AutocompleteScreen";
import { HotelsProvider } from "./domain/HotelsScreen/HotelsContext";
import { ReturnFlowStateProvider } from "./domain/SegmentScreen/ReturnFlowStateProvider";
import { TravelSearchSettingsProvider } from "./domain/SegmentScreen/TravelSearchSettingsProvider";
import { TopNav } from "./domain/TopNav/TopNav";
import { TripPlannerProvider } from "./domain/TripPlanner/TripPlannerProvider";
import { UNSAFE_useConfigureFeatureEscapeHatch } from "./feature/UNSAFE_useConfigureFeatureEscapeHatch";
import { useFeature } from "./feature/useFeature";
import { HoveredIndexProvider } from "./HoveredIndexProvider";
import MapPane from "./MapPane";
import { PaneContent } from "./PaneContent";
import { ScrollContext } from "./ScrollContext";
import SearchBar from "./SearchBar";
import { useCachedResponse } from "./utils/hooks/useCachedResponse";
import { useDisableAdsUrlParams } from "./utils/hooks/useDisableAdsUrlParams";
import { useGetPermaLink } from "./utils/hooks/useGetPermaLink";
import { useHeadMeta } from "./utils/hooks/useHeadMeta";
import { useHeadTitle } from "./utils/hooks/useHeadTitle";
import { initializeHotjarOnConsent } from "./utils/hooks/useHotjar";
import { useIsTripScreen } from "./utils/hooks/useIsTripScreen";
import { useLanguageAttribute } from "./utils/hooks/useLanguageAttribute";
import { desktopLayout, useLayout } from "./utils/hooks/useLayout";
import { useMarker } from "./utils/hooks/useMarker";
import { useIsHotelsUrlDeeplink } from "./utils/hooks/useNavigateToHotelsPage";
import { usePath } from "./utils/hooks/usePath";
import useSearch from "./utils/hooks/useSearch";
import { useSetFeatureCookie } from "./utils/hooks/useSetFeatureCookie";
import { useSwitchToHotelsNear } from "./utils/hooks/useSwitchToHotelsNear";
import { useTypedLocation } from "./utils/hooks/useTypedLocation";
import logBreadcrumbs from "./utils/logBreadcrumbs";
import logContext from "./utils/logContext";
import { useDetectAdBlocker } from "./utils/useDetectAdBlocker";
import { registerGoogleConsent } from "./data-partners/google/registerGoogleConsent";

export type LocationState = {
  autocomplete?: AutocompleteKind;
  searchBar?: "static" | "edit";
  carrierCode?: string;
  schedulePicker?: boolean;
  scheduleIndex?: number;
  ticketPickerView?: View; // The View that opened the ticket picker so that exit logging is assigned correctly.
  highlightedTab?: MobileTabs;
  preferencesScreen?: "main" | "currency" | "language" | "distance" | "debug";
};

export type MobileTabs = "search" | "preferences" | "hotels" | "trips";

export function App() {
  UNSAFE_useConfigureFeatureEscapeHatch();
  const intl = useIntl();
  const location = useTypedLocation();
  const { returnsFlowLocation } = location.state ?? {};
  const { origin, destination, searchResponse } = useSearch();
  const permalink = useGetPermaLink();
  const isTripScreen = useIsTripScreen();
  const reactQueryDevTools = useFeature("ReactQueryDevTools");
  usePath(origin?.canonicalName, destination?.canonicalName);
  useHeadTitle(origin?.shortName, destination?.shortName);
  const isHotelsScreen = useIsHotelsUrlDeeplink();
  useHeadMeta(
    isHotelsScreen
      ? intl.formatMessage(messages.hotelDescription, {
          destination: destination?.shortName,
        })
      : intl.formatMessage(messages.description)
  );
  useLanguageAttribute();
  useSwitchToHotelsNear();
  useSetFeatureCookie();
  useInitializeGoogleSignin();
  useInitializeAppleSignin();
  useReducedMotion();
  useMarker();

  useEffect(() => {
    initializeTcDataAnalyticsLogging((category, action, label, value) =>
      sendAnalyticsNonInteractionEvent({
        category: category as unknown as AnalyticsEventCategory,
        action: action,
        label: label,
        value: value,
      })
    );
    initializeHotjarOnConsent();
    registerMarketingDataPartners();
  }, []);

  useEffect(() => {
    registerAdaraConsent(
      searchResponse?.analytics?.interest_data?.Adara?.enabled
    );
    registerCrimtanConsent(
      searchResponse?.analytics?.interest_data?.Crimtan?.enabled
    );
    registerGoogleConsent();
  }, [
    searchResponse?.analytics?.interest_data?.Adara?.enabled,
    searchResponse?.analytics?.interest_data?.Crimtan?.enabled,
  ]);

  const adBlockerDetected = useDetectAdBlocker();

  useEffect(() => {
    if (adBlockerDetected) {
      sendAnalyticsNonInteractionEvent({
        category: "App",
        action: "AdBlockerDetected",
      });
    }
  }, [adBlockerDetected]);

  const layout = useLayout((layout, lastLayout) => {
    logBreadcrumbs("Layout", `Layout change: ${lastLayout} -> ${layout}`);
  });

  // If the user pastes a saved url, we need to default to the correct tab on mobile.
  function chooseDefaultTabHighlight() {
    if (isTripScreen) {
      return "trips";
    } else if (isHotelsScreen) {
      return "hotels";
    }
    return "search";
  }

  const geocodeOrigin = origin?.canonicalName;
  const geocodeDestination = destination?.canonicalName;
  const responseOrigin = searchResponse?.request?.oName;
  const responseDestination = searchResponse?.request?.dName;
  useEffect(() => {
    logContext({
      layout: layout,
      responseOrigin: responseOrigin,
      responseDestination: responseDestination,
      geocodeOrigin: geocodeOrigin,
      geocodeDestination: geocodeDestination,
      permalink: permalink,
    });
  }, [
    layout,
    responseOrigin,
    responseDestination,
    geocodeOrigin,
    geocodeDestination,
    permalink,
  ]);

  // On mobile default to using the <html /> element for scrolling
  const scrollRef = useRef<HTMLElement>(document.documentElement);
  const getScrollTop = useCallback(() => scrollRef.current?.scrollTop ?? 0, []);
  const setScrollTop = useCallback((top: number) => {
    if (scrollRef.current) scrollRef.current.scrollTop = top;
  }, []);

  const highlightedTab =
    location.state?.highlightedTab ?? chooseDefaultTabHighlight();

  // Cache the search response for the map so that when a new request
  // is made the map doesn't get refreshed.
  const cachedResponse = useCachedResponse(searchResponse);

  const UseScheduleDetailScreen =
    location.state?.openScheduleIndex !== undefined && !isHotelsScreen;

  const isMapEnabled = layout !== "mobile" || isHotelsScreen;
  const wasMapEnabled = useLatch(isMapEnabled);

  // Once the map is loaded, keep it loaded even when it is not visible
  // so that we can avoid reloading the map which costs us money.
  const shouldRenderMap = !!((isMapEnabled || wasMapEnabled) && cachedResponse);

  // During an experiment where the popunder will send users to the hotels screen,
  // we do not want to serve any ads on the hotels screen.
  const isHExPopunderDisabledAds = useDisableAdsUrlParams();

  const shouldShowRightRail =
    layout !== "mobile" && !(isHotelsScreen && isHExPopunderDisabledAds);

  return (
    <ScrollContext.Provider
      value={{
        getScrollTop,
        setScrollTop,
        scrollRef,
      }}
    >
      <TravelSearchSettingsProvider>
        <HoveredIndexProvider>
          <TripPlannerProvider>
            <ReturnFlowStateProvider returnsFlowLocation={returnsFlowLocation}>
              <HotelsProvider>
                <AppContainer>
                  <VisuallyHidden>
                    <h1>{intl.formatMessage(messages.appName)}</h1>
                  </VisuallyHidden>
                  {layout !== "mobile" && (
                    <Header>
                      <SkipLink />
                      <TopNav />
                      <SearchBar />
                    </Header>
                  )}
                  <DefaultErrorBoundary defaultMessage>
                    <Layout
                      ref={(el) => {
                        if (el && layout === "desktop") {
                          scrollRef.current = el;
                        }
                      }}
                      leftPane={
                        <PaneContent
                          highlightedTab={highlightedTab}
                          layout={layout}
                          sectionTitle={intl.formatMessage(
                            messages.resultsSectionTitle
                          )}
                          openScheduleIndex={location.state?.openScheduleIndex}
                          returnsFlowLocation={returnsFlowLocation}
                        />
                      }
                      background={
                        isMapEnabled ? (
                          <MapPane
                            isTripScreen={isTripScreen}
                            shouldRenderMap={shouldRenderMap}
                            searchResponse={cachedResponse}
                            returnsFlowLocation={returnsFlowLocation}
                          />
                        ) : null
                      }
                      rightRail={shouldShowRightRail && <RightRail />}
                    />
                  </DefaultErrorBoundary>

                  {layout === "mobile" && !UseScheduleDetailScreen && (
                    <StyledBottomNavbar
                      active={highlightedTab}
                      setScrollTop={setScrollTop}
                    />
                  )}
                </AppContainer>
              </HotelsProvider>
            </ReturnFlowStateProvider>
          </TripPlannerProvider>
        </HoveredIndexProvider>
      </TravelSearchSettingsProvider>
      {reactQueryDevTools && <ReactQueryDevtools initialIsOpen={false} />}
    </ScrollContext.Provider>
  );
}

/**
 * A hook that returns true if the value passed in was ever true.
 */
function useLatch(value: boolean): boolean {
  const ref = useRef(value);
  useEffect(() => {
    ref.current ||= value;
  }, [value]);
  return ref.current;
}

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${desktopLayout} {
    height: 100vh;
  }
`;

const Header = styled.header`
  position: relative;
  z-index: 10;
  box-shadow: 0px 1px 3px rgba(31, 30, 30, 0.24);
  background-color: ${color.bg.fill.fill};
`;

const StyledBottomNavbar = styled(BottomNavbar)`
  position: fixed;
  bottom: 0;
`;
