import { useState } from "react";
import styled from "styled-components";
import { Icon } from "src/components/Icon/Icon";
import ChevronDown from "src/svg/ChevronDown.svg?react";
import { spacing } from "src/design-system/tokens/spacing";
import color from "src/design-system/tokens/color";
import { useExperimentConfig } from "src/experiment/useExperimentConfig";
import {
  experimentDefinitions,
  type ExperimentName,
  type ExperimentVariant,
} from "src/experiment/ExperimentDefinition";
import { useTypedLocation } from "src/utils/hooks/useTypedLocation";
import { useNavigate } from "react-router-dom";
import { searchParamsChange } from "src/utils/location/searchParamsChange";
import useSearch from "src/utils/hooks/useSearch";
import { border_radius } from "src/design-system/tokens/border";
import {
  BodyMd,
  HeadingMd,
} from "src/design-system/components/Typography/Typography";
import { useMediumMediaQuery } from "src/utils/hooks/useScreenSize";
import { FeatureItem } from "./DebugItem";

const sortedExperiments = Object.entries(experimentDefinitions).sort(
  ([nameA], [nameB]) => nameA.localeCompare(nameB)
);

export function BackendExperimentSelector() {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useTypedLocation();
  const navigate = useNavigate();
  const { searchResponse } = useSearch();
  const experimentConfig = useExperimentConfig();
  const isMediumScreen = useMediumMediaQuery();

  const updateExperimentParams = (
    searchParams: URLSearchParams,
    updatedExperimentName: ExperimentName,
    selectedVariant: ExperimentVariant<ExperimentName>
  ) => {
    const newExperimentValue = `${updatedExperimentName}:${selectedVariant}`;
    const backendExperimentParams =
      searchParams.get("backendExperiments")?.split(",") ?? [];
    const experiments: string[] = [newExperimentValue];

    for (const experiment of backendExperimentParams) {
      const [experimentName] = experiment.split(":");

      if (experimentName !== updatedExperimentName) {
        experiments.push(experiment);
      }
    }

    return experiments.join(",");
  };

  const handleChange = (
    experimentName: ExperimentName,
    variant: ExperimentVariant<ExperimentName>
  ) => {
    const searchParams = new URLSearchParams(location.search);
    const backendExperiments = updateExperimentParams(
      searchParams,
      experimentName,
      variant
    );
    searchParams.set("backendExperiments", backendExperiments);

    navigate(searchParamsChange(searchParams, location), {
      replace: true,
      ...(!isMediumScreen && {
        state: {
          highlightedTab: "preferences",
          preferencesScreen: "debug",
        },
      }),
    });

    // Reload the page to apply the new experiment config.
    window.location.reload();
  };

  return (
    <>
      <FeatureItem onClick={() => setIsExpanded((prev) => !prev)} pointer>
        <h3>Backend Experiments</h3>
        <StyledChevronIcon pointUp={isExpanded} size="sm">
          <ChevronDown color={color.primitive.pointOfInterestChurch100} />
        </StyledChevronIcon>
      </FeatureItem>
      {isExpanded && (
        <>
          {searchResponse?.analytics?.experiment_config ? (
            sortedExperiments.map((experiment) => {
              const experimentName = experiment[0] as ExperimentName;
              const { experimentId, variants } = experiment[1];
              const uniqueVariants = [...new Set(variants)];
              const activeVariant =
                experimentConfig?.[experimentName] ?? uniqueVariants[0];

              return (
                <FeatureItem key={experimentName}>
                  <Label htmlFor={experimentId}>{experimentName}</Label>
                  <StyledSelect
                    id={experimentId}
                    defaultValue={activeVariant}
                    onChange={(event) => {
                      const newVariant = event.target
                        .value as ExperimentVariant<ExperimentName>;
                      handleChange(experimentName, newVariant);
                    }}
                  >
                    {uniqueVariants.map((variant) => (
                      <option key={variant} value={variant}>
                        {variant}
                      </option>
                    ))}
                  </StyledSelect>
                </FeatureItem>
              );
            })
          ) : (
            <Container>
              <Title>Debug experiments unavailable</Title>
              <BodyMd>
                Make sure you have a UID assigned. This can be set by clicking
                the `Assign UID` button at the bottom of this debug menu.
              </BodyMd>
            </Container>
          )}
        </>
      )}
    </>
  );
}

const StyledChevronIcon = styled(Icon)<{
  pointUp?: boolean;
}>`
  margin-left: ${spacing.md};

  ${({ pointUp }) => pointUp && `transform: rotate(180deg);`}
`;

const Label = styled.label`
  margin-right: ${spacing.md};
`;

const StyledSelect = styled.select`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
  padding: ${spacing.sm} ${spacing.xl} ${spacing.sm} ${spacing.xs};
`;

const Container = styled.div`
  margin: ${spacing.md};
  padding: ${spacing.xl};
  background: ${color.bg.fill.active};
  border-radius: ${border_radius.rounded_lg};
`;

const Title = styled(HeadingMd)`
  display: block;
  margin-bottom: ${spacing.sm};
`;
