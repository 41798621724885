import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { sendAnalyticsInteractionEvent } from "src/analytics/sendAnalyticsEvent";
import { border_radius } from "src/design-system/tokens/border";
import color from "src/design-system/tokens/color";
import { spacing } from "src/design-system/tokens/spacing";
import { localeToLanguageCode } from "src/utils/conversions/languageCode";
import {
  useSearchInput,
  useSearchKeyboard,
} from "src/utils/hooks/useSearchInput";
import { getPath } from "src/utils/url";
import styled from "styled-components";
import { type FocusedElement, FocusContext } from "../../FocusContext";
import type { AutocompletePlace } from "../../api/AutocompleteResponse";
import { ButtonBase } from "../../components/Button/ButtonBase";
import { ClickAwayListener } from "../../components/ClickAwayListener/ClickAwayListener";
import { Icon } from "../../components/Icon/Icon";
import { Switch } from "../../svg/Switch";
import useSearch from "../../utils/hooks/useSearch";
import { useTheme } from "../../utils/hooks/useTheme";
import messages from "./LargeSearchBar.messages.ts";
import { SearchAutocomplete } from "./SearchAutocomplete/SearchAutocomplete";

export function LargeSearchBar() {
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const { origin: searchOrigin, destination: searchDestination } = useSearch();
  const keyboard = useSearchKeyboard();
  const originSearch = useSearchInput(
    {
      id: "origin",
      place: searchOrigin,
      onSelect: handleOriginAutocompleteSelect,
      onAfterSelect: () => destinationSearch.inputRef.current?.focus(),
    },
    keyboard
  );

  const destinationSearch = useSearchInput(
    {
      id: "destination",
      place: searchDestination,
      onSelect: handleDestinationAutocompleteSelect,
      onAfterSelect: () => destinationSearch.inputRef.current?.blur(),
    },
    keyboard
  );

  function handleSwitch() {
    sendAnalyticsInteractionEvent({
      category: "SearchBox",
      action: "Click:Switch",
    });

    if (searchOrigin?.canonicalName === searchDestination?.canonicalName)
      return;

    originSearch.autocompleteState.changeDisplayValueWithoutResults(
      originSearch.autocompleteState.displayValue
    );

    originSearch.autocompleteState.changeDisplayValueWithoutResults(
      destinationSearch.autocompleteState.displayValue
    );

    originSearch.setIsResultsClosed(true);
    destinationSearch.setIsResultsClosed(true);

    navigate({
      pathname: getPath(
        searchDestination?.canonicalName,
        searchOrigin?.canonicalName,
        localeToLanguageCode(intl.locale)
      ),
      search: window.location.search,
    });
  }

  function handleOriginAutocompleteSelect(
    place: AutocompletePlace | undefined
  ) {
    if (!place) return;
    sendAnalyticsInteractionEvent({
      category: "Autocomplete",
      action: "Click:AutocompleteOption",
    });
    navigate({
      pathname: getPath(
        place.canonicalName,
        searchDestination?.canonicalName,
        localeToLanguageCode(intl.locale)
      ),
      search: window.location.search,
    });
  }

  function handleDestinationAutocompleteSelect(
    place: AutocompletePlace | undefined
  ) {
    if (!place) return;
    sendAnalyticsInteractionEvent({
      category: "Autocomplete",
      action: "Click:AutocompleteOption",
    });
    navigate({
      pathname: getPath(
        searchOrigin?.canonicalName,
        place.canonicalName,
        localeToLanguageCode(intl.locale)
      ),
      search: window.location.search,
    });
  }

  function onFocusChanged(newFocusedElement: FocusedElement) {
    const numResults =
      newFocusedElement.id === "origin"
        ? originSearch.displayResults.length
        : destinationSearch.displayResults.length;
    keyboard.setFocusedElement(newFocusedElement, numResults);
  }

  return (
    <FocusContext.Provider
      value={{ focusedElement: keyboard.focusedElement, onFocusChanged }}
    >
      <Container>
        <InputClickAwayListener
          onClickAway={() => originSearch.setIsResultsClosed(true)}
        >
          <SearchAutocomplete
            kind="origin"
            value={originSearch.displayValue}
            onChange={(e) => originSearch.handleQueryChange(e.target.value)}
            results={originSearch.displayResults}
            onAutocompleteSelect={handleOriginAutocompleteSelect}
            hint={originSearch.hint}
            ref={originSearch.inputRef}
            onKeyDown={(event) =>
              keyboard.onKeyDown(
                event,
                originSearch.handleEnter,
                originSearch.isResultsClosed,
                originSearch.displayResults.length
              )
            }
            reset={() => {
              originSearch.autocompleteState.changeDisplayValueWithoutResults(
                searchOrigin?.longName ?? ""
              );
            }}
          />
        </InputClickAwayListener>

        <SwitchButton
          onClick={handleSwitch}
          aria-label={intl.formatMessage(messages.switchLabel)}
        >
          <Icon size="lg">
            <Switch
              title="switch"
              tint={theme.searchBar.switchButton.iconTint}
            />
          </Icon>
        </SwitchButton>

        <InputClickAwayListener
          onClickAway={() => destinationSearch.setIsResultsClosed(true)}
        >
          <SearchAutocomplete
            kind="destination"
            value={destinationSearch.displayValue}
            onChange={(e) =>
              destinationSearch.handleQueryChange(e.target.value)
            }
            results={destinationSearch.displayResults}
            onAutocompleteSelect={handleDestinationAutocompleteSelect}
            hint={destinationSearch.hint}
            ref={destinationSearch.inputRef}
            onKeyDown={(event) =>
              keyboard.onKeyDown(
                event,
                destinationSearch.handleEnter,
                destinationSearch.isResultsClosed,
                destinationSearch.displayResults.length
              )
            }
            reset={() => {
              destinationSearch.autocompleteState.changeDisplayValueWithoutResults(
                searchDestination?.longName ?? ""
              );
            }}
          />
        </InputClickAwayListener>
      </Container>
    </FocusContext.Provider>
  );
}

const InputClickAwayListener = styled(ClickAwayListener)`
  flex: 1 1;
  max-width: 240px;
`;

const SwitchButton = styled(ButtonBase)`
  border-radius: ${border_radius.rounded_md};
  width: 48px;
  min-width: 48px;
  // We want the inner icon to be 18px in size, so we apply 15px padding because
  // 48px - 30px = 18px.
  padding: 15px;
  height: 48px;
  background-color: ${color.bg.surface.active};
  border: 1px solid ${color.border.secondary};

  &:hover {
    background-color: ${color.input.bgSurfaceActive};

    // Reset the background-color on touch devices so that they don't get a
    // lingering hover effect after a click event.
    @media (hover: none) {
      background-color: ${color.input.bgSurface};
    }
  }

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${spacing.xs} ${spacing.xxl} ${spacing.md};
  & > * {
    margin-right: ${spacing.md};
  }
`;
